import { getAllAsDictionary, openIdb } from '@/idb';
import Message from '@/models/Message';
import MessageType from '@/models/MessageType';
import { defineStore } from 'pinia';

const state = () => ({
	debugging: process.env.NODE_ENV !== 'production' || self.location.hostname === 'localhost' || /\.ngrok\.io$/.test(self.location.hostname),
	settings: {
		showVideos: false,
		useJsKeyboard: false,
		inactivityReset: false,
		metricUnits: actions.setDefaultEU(),
		hideUsRegions: actions.setDefaultEU(),
	},
	// installPrompt: null,
	showInstallBanner: false,
	showInstallButton: false,
	serviceWorkerUpdate: false,
	messages: [],
});

const getters = {
};

const actions = {
	async refreshSettings() {
		const idb = await openIdb();
		const values = await getAllAsDictionary(idb, 'settings');
		this.settings.showVideos = values.showVideos ?? false;
		this.settings.useJsKeyboard = values.useJsKeyboard ?? false;
		this.settings.inactivityReset = values.inactivityReset ?? false;
		this.settings.metricUnits = values.metricUnits ?? actions.setDefaultEU();
		this.settings.hideUsRegions = values.hideUsRegions ?? this.setDefaultEU();
	},
	removeMessage(messageId) {
		const index = this.messages.findIndex(x => x.id === messageId);
		if (index >= 0) {
			this.messages.splice(index, 1);
		}
	},
	addMessage(messageText, type = MessageType.info, autoClose = true) {
		if (!(typeof messageText === 'string')) { return; }
		messageText = messageText.trim();
		if (!messageText) { return; }

		const currentId = this.messages.length > 0 ? this.messages.reduce((id, x) => Math.max(id, x.id), 0) : 0;
		const message = new Message();
		message.id = currentId + 1;
		message.type = MessageType.isValid(type) ? type : MessageType.info;
		message.message = messageText;
		message.autoClose = !!autoClose;

		if (this.messages.findIndex(x => x.id === message.id) >= 0) { return; }
		this.messages.push(message);
		if (autoClose) {
			setTimeout(() => {
				this.removeMessage(message.id);
				// 1s delay for animate out:
			}, Message.AUTO_CLOSE_DELAY + 1000);
		}
	},
	setCustomPwaBanner(status) {
		if (status && localStorage.getItem('pwaUserChoice') != 'no') {
			this.showInstallBanner = true;
		} else {
			this.showInstallBanner = false;
		}
	},
	// setPwaPrompt(event) {
	// 	if (event != null) {
	// 		this.installPrompt = event;
	// 	}
	// },
	setDefaultEU() {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return timeZone.startsWith('Europe/');
	}
};

export const useStore = defineStore('general', {
	state,
	getters,
	actions,
});
